
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class DateSearch extends Vue {
  @Prop({ default: () => new Date() })
  readonly value: Date | undefined;

  @Prop({ default: () => false })
  readonly loading: boolean | undefined;

  @Prop({ default: () => false })
  readonly disabled: boolean | undefined;

  @Prop({ default: () => undefined })
  readonly color: string | undefined;

  menu = false;

  get date(): Date {
    return this.value || new Date();
  }

  set date(value: Date) {
    this.$emit('input', value)
    this.$emit('action', value)
  }
}
